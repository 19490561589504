//
//
//
//
//
//
//
//
//
//
//
//
//

import get from 'lodash/get'
import Clipr from '~/components/Clipr.vue'
// eslint-disable-next-line camelcase
import api_client from '~/utilities/CliprNoCookieAPI'
import { getIndividualRequestPath } from '~/services/request'
import { getFontsCSSText } from '~/services/fonts'
import { generateMeta } from '~/services/meta'
import { formatTextStickers } from '~/utilities/functions'

export default {
  name: 'App',
  components: {
    Clipr,
  },
  async asyncData({ req, params, store, $sentry }) {
    const cliprIsList = false
    const cliprTestMode = false

    // Retrieve stories content
    const path = getIndividualRequestPath(req, params, cliprTestMode)
    const request = api_client()

    try {
      const response = await request.get(path)
      const fontStyles = getFontsCSSText(response.data)
      formatTextStickers(response.data)

      return {
        cliprIsList,
        cliprTestMode,
        cssFonts: fontStyles,
        headData: generateMeta(response.data),
        initialData: response.data,
      }
    } catch (e) {
      const status = get(e, 'response.status', 0)
      if ($sentry && (status >= 500 || !e.response)) {
        $sentry.captureException(e)
      }
      return {}
    }
  },
  data() {
    return {
      cliprOnlyNative: false,
      cliprSimulator: false,
      cliprPixelImpression: '',
      cssFonts: '',
      initialData: null,
      headData: {
        title: '',
        meta: [],
        link: [],
      },
    }
  },
  head() {
    return {
      style: [{ cssText: this.cssFonts, type: 'text/css' }],
      title: this.headData.title,
      meta: this.headData.meta,
      link: this.headData.link,
      __dangerouslyDisableSanitizers: ['style'],
    }
  },
}
